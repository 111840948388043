import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { auth } from '../../firebase';

const DeleteAdmin = () => {
  const handleLogout = () => {
    auth.signOut();
  };

  return (
    <>
      <Link to={'/setting'}>
        <Button
          sx={{
            position: 'fixed',
            zIndex: '99',
            right: '20px',
            bottom: '80px',
            backgroundColor: 'black',
            fontSize: '20px',
            color: 'white',
            '&:hover': {
              backgroundColor: 'black', // Изменение цвета при наведении на кнопку
            },
          }}
        >
          До кабінету
        </Button>
      </Link>
      <Button
        sx={{
          position: 'fixed',
          zIndex: '100',
          right: '20px',
          bottom: '20px',
          backgroundColor: 'black',
          fontSize: '20px',
          color: 'white',
          '&:hover': {
            backgroundColor: 'black', // Изменение цвета при наведении на кнопку
          },
        }}
        onClick={handleLogout}
      >
        Вийти з Адміністратора
      </Button>
    </>
  );
};

export default DeleteAdmin;
