import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useEffect, useState } from 'react';
import { setDoc, doc } from 'firebase/firestore';

const storage = getStorage();

// додаємо 1 фото і повертаємо лінк
export const addPhoto = async (fileName, productImage) => {
  try {
    const storageRef = ref(storage, `products/${fileName}`);
    const snapshot = await uploadBytes(storageRef, productImage);
    const downloadURL = await getDownloadURL(snapshot.ref);
    console.log('File available at', downloadURL);
    return downloadURL; // Возвращаем URL-адрес
  } catch (error) {
    console.error('Error uploading or getting download URL:', error);
    throw error; // Пробрасываем ошибку дальше
  }
};

export const getId = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const hours = now.getHours();
  const minutes = now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();
  const seconds = now.getSeconds() < 10 ? `0${now.getSeconds()}` : now.getSeconds();
  return `${year}${month}${day}${hours}${minutes}${seconds}`;
};

export const scrollToElement = () => {
  const section = document.getElementById('top');
  if (section) {
    const offset = 100; // Отступ сверху
    const offsetPosition = section.getBoundingClientRect().top + window.scrollY - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth', // Плавный скролл
    });
  }
};

// // показываем корректные наименования
export const getTranslation = (value, array, boolin) => {
  const item = array.find((item) => item.value === value);
  if (boolin) {
    return item ? item.label.uk : '';
  } else {
    return item ? item.label : '';
  }
};

export const categoryArray = [
  { value: "childrens-beds", label: "Дитячі ліжка" },
  { value: "mattresses", label: "Матраси та наматрасники" },
  { value: "dressers", label: "Комоди" },
  { value: "bed-sheets", label: "Постіль" },
];

export const subСategoriesArray = [
  { value: 1, label: "Маятник без шухляди" },
  { value: 2, label: "На колесах" },
  { value: 3, label: "Овальні" },
  { value: 6, label: "Маятник з шухлядою" },
  { value: 4, label: "Наматрацники" },
  { value: 5, label: "Без пеленальної поверхні" },
];

export const colors = [
  { value: "alyaska", label: "Аляска" },
  { value: "anthracite", label: "Антрацит" },
  { value: "beige", label: "Бежевий" },
  { value: "white", label: "Білий" },
  { value: "turquoise", label: "Бірюзовий" },
  { value: "blue", label: "Блакитний" },
  { value: "wenge", label: "Венге" },
  { value: "yellow", label: "Жовтий" },
  { value: "nut", label: "Горіх" },
  { value: "sonoma", label: "Дуб сонома" },
  { value: "brown", label: "Коричневий" },
  { value: "cream", label: "Кремовий" },
  { value: "natural", label: "Натуральний" },
  { value: "olive", label: "Оливковий" },
  { value: "peach", label: "Персиковий" },
  { value: "orange", label: "Помаранчевий" },
  { value: "pink", label: "Рожевий" },
  { value: "lightGreen", label: "Салатовий" },
  { value: "gray", label: "Сірий" },
  { value: "grayAndWhite", label: "Сіро-білий" },
  { value: "vanilla", label: "Слонова кістка(Ваніль)" },
  { value: "purple", label: "Фіолетовий" },
  { value: "red", label: "Червоний" }
];

export const size = [
  { value: 6, label: "6см" },
  { value: 7, label: "7см" },
  { value: 8, label: "8см" },
  { value: 9, label: "9см" },
  { value: 10, label: "10см" },
  { value: 11, label: "11см" },
  { value: 12, label: "12см" },
];

export const brands = [
  { value: "dubik", label: "Дубик М" },
  { value: "babyCofort", label: "Baby Cofort" },
  { value: "deSon", label: "DeSon" },
  { value: "childrensDream", label: "Children`s Dream" },
  { value: "kuzy", label: "Кузя" },
  { value: "dominata", label: "Домінанта" },
  { value: "flitex", label: "Flitex" },
  { value: "goodNight", label: "Mati" },
];

const EditablePrice = ({ initialPrice, itemId, db, onPriceUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [price, setPrice] = useState(initialPrice);

  useEffect(() => {
    setPrice(initialPrice);
  }, [initialPrice]);

  const handlePriceClick = () => {
    setIsEditing(true);
  };

  const handleBlur = async () => {
    setIsEditing(false);
    if (price !== initialPrice) {
      try {
        const itemRef = doc(db, 'data', itemId);
        await setDoc(itemRef, { price }, { merge: true });
        onPriceUpdate(itemId, price);
      } catch (error) {
        console.error('Error saving price:', error);
        setPrice(initialPrice);
      }
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    // Разрешаем только числа и точку
    if (/^\d*\.?\d*$/.test(value) || value === '') {
      setPrice(value);
    }
  };

  return (
    <div className="setting__item-info-others">
      Ціна: {
        isEditing ? (
          <input
            type="text"
            value={price}
            onChange={handleChange}
            onBlur={handleBlur}
            className="setting__item-info-others__input"
            autoFocus
          />
        ) : (
          <span
            onClick={handlePriceClick}
          >
            {price}
          </span>
        )
      }
    </div>
  );
};

export default EditablePrice;
