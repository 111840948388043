import React, { useState } from 'react';
import { setDoc, doc, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, storage } from '../../../../firebase';
import Snackbar from '@mui/material/Snackbar';
import imageCompression from 'browser-image-compression'; // Don't forget to install this package
import './Instagram.css';

export default function Instagram({ data }) {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [instagramInputs, setInstagramInputs] = useState({
    file: null,
    linkInstagram: '',
  });
  const [stockInputs, setStockInputs] = useState({
    file: null,
    text: '',
    description: '',
  });

  const handleAlertClose = () => {
    setOpenAlert(false);
    setAlert('');
  };

  const showNotification = (message) => {
    setAlert(message);
    setOpenAlert(true);
  };

  // New image compression function
  const compressImage = async (file) => {
    // Поддерживаемые форматы
    const supportedFormats = ['image/jpeg', 'image/png'];

    if (!supportedFormats.includes(file.type)) {
      throw new Error('Непідтримуваний формат файлу. Завантажуйте лише зображення JPEG або PNG.');
    }

    const options = {
      maxSizeMB: 0.4,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      fileType: 'image/webp',
      initialQuality: 0.7,
    };

    try {
      const compressedBlob = await imageCompression(file, options);
      // Create new filename with .webp extension
      const newFileName = file.name.replace(/\.[^/.]+$/, '.webp');
      return new File([compressedBlob], newFileName, { type: 'image/webp' });
    } catch (error) {
      console.error('Error compressing image:', error);
      throw error;
    }
  };

  // Updated Instagram file input handler
  const handleInstagramFileChange = async (e) => {
    try {
      if (e.target.files.length > 0) {
        const file = e.target.files[0];
        const compressedFile = await compressImage(file);
        setInstagramInputs((prev) => ({ ...prev, file: compressedFile }));
      }
    } catch (error) {
      showNotification(error.message);
      setInstagramInputs((prev) => ({ ...prev, file: null }));
    }
  };

  // Updated Stock file input handler
  const handleStockFileChange = async (e) => {
    try {
      if (e.target.files.length > 0) {
        const file = e.target.files[0];
        const compressedFile = await compressImage(file);
        setStockInputs((prev) => ({ ...prev, file: compressedFile }));
      }
    } catch (error) {
      showNotification(error.message);
      setStockInputs((prev) => ({ ...prev, file: null }));
    }
  };

  // Handle Instagram image upload
  const handleInstagramUpload = async () => {
    try {
      setLoading(true);
      if (!instagramInputs.file || !instagramInputs.linkInstagram) {
        showNotification('Будь ласка, заповніть всі поля');
        return;
      }

      // Upload image to Storage
      const storageRef = ref(storage, `instagram/${instagramInputs.file.name}`);
      await uploadBytes(storageRef, instagramInputs.file);
      const imageUrl = await getDownloadURL(storageRef);

      // Add to Firestore
      const newInstagramPost = {
        img: [imageUrl],
        link: instagramInputs.linkInstagram,
      };

      await setDoc(doc(db, 'instagram', Date.now().toString()), newInstagramPost);

      // Reset form
      setInstagramInputs({ file: null, linkInstagram: '' });
      showNotification('Пост Instagram успішно додано!');
    } catch (error) {
      console.error('Error adding instagram post:', error);
      showNotification('Помилка при додаванні поста Instagram');
    } finally {
      setLoading(false);
    }
  };

  // Handle Stock upload
  const handleStockUpload = async () => {
    try {
      setLoading(true);
      if (!stockInputs.file || !stockInputs.text || !stockInputs.description) {
        showNotification('Будь ласка, заповніть всі поля');
        return;
      }

      // Upload image to Storage
      const storageRef = ref(storage, `stock/${stockInputs.file.name}`);
      await uploadBytes(storageRef, stockInputs.file);
      const imageUrl = await getDownloadURL(storageRef);

      // Add to Firestore
      const newStock = {
        img: [imageUrl],
        text: stockInputs.text,
        description: stockInputs.description,
      };

      await setDoc(doc(db, 'stock', Date.now().toString()), newStock);

      // Reset form
      setStockInputs({ file: null, text: '', description: '' });
      showNotification('Акцію успішно додано!');
    } catch (error) {
      console.error('Error adding stock:', error);
      showNotification('Помилка при додаванні акції');
    } finally {
      setLoading(false);
    }
  };

  // Handle delete Instagram post
  const handleDeleteInstagram = async (id, imageUrl) => {
    try {
      await deleteDoc(doc(db, 'instagram', id));
      const imageRef = ref(storage, imageUrl);
      await deleteObject(imageRef);
      showNotification('Пост Instagram успішно видалено!');
    } catch (error) {
      console.error('Error deleting instagram post:', error);
      showNotification('Помилка при видаленні поста Instagram');
    }
  };

  // Handle delete Stock
  const handleDeleteStock = async (id, imageUrl) => {
    try {
      await deleteDoc(doc(db, 'stock', id));
      const imageRef = ref(storage, imageUrl);
      await deleteObject(imageRef);
      showNotification('Акцію успішно видалено!');
    } catch (error) {
      console.error('Error deleting stock:', error);
      showNotification('Помилка при видаленні акції');
    }
  };

  return (
    <div>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={2000} open={openAlert} onClose={handleAlertClose} message={alert} key={'top-center'} />
      <div className="instagram-carusel container">
        <p className="instagram-carusel__title">Зображення у Instagram</p>
        <div className="instagram-carusel__box">
          {data &&
            data.instagram.map((item, index) => (
              <div key={index} className="instagram-carusel__item">
                <img src={item.img[0]} className="img-cover" alt="instagram" />
                <button className="instagram-carusel__item-delete" onClick={() => handleDeleteInstagram(item.id, item.img[0])} disabled={loading}>
                  Видалити
                </button>
              </div>
            ))}
        </div>
        <div className="instagram-upload">
          <input className="instagram-upload__input" type="file" onChange={handleInstagramFileChange} />
          <input
            className="instagram-upload__input"
            type="text"
            placeholder="Посилання на товар в Instagram"
            name="linkInstagram"
            value={instagramInputs.linkInstagram}
            onChange={(e) => setInstagramInputs((prev) => ({ ...prev, linkInstagram: e.target.value }))}
          />
          <button className="instagram-upload__button" onClick={handleInstagramUpload} disabled={loading}>
            {loading ? 'Завантаження...' : 'Додати зображення'}
          </button>
        </div>
      </div>

      {data && (
        <div className="instagram-carusel container">
          <p className="instagram-carusel__title">Акції</p>
          <div className="instagram-stock__box">
            {data.stock &&
              data.stock.map((item, index) => (
                <div key={index} className="instagram-stock__box__item">
                  <div className="instagram-stock__box__item-img">{item.img && <img src={item.img[0]} className="img-cover" alt="Stock" />}</div>
                  <div className="instagram-stock__box__item-text">{item.text}</div>
                  <div className="instagram-stock__box__item-description">{item.description}</div>
                  <button className="instagram-stock__box__item-delete" onClick={() => handleDeleteStock(item.id, item.img[0])} disabled={loading}>
                    Видалити
                  </button>
                </div>
              ))}
          </div>
          <div className="stock-upload">
            <input className="instagram-upload__input" type="file" onChange={handleStockFileChange} />
            <input
              className="instagram-product__input"
              name="text"
              type="text"
              placeholder="Назва акції"
              value={stockInputs.text}
              onChange={(e) => setStockInputs((prev) => ({ ...prev, text: e.target.value }))}
            />
            <input
              className="instagram-product__input"
              name="description"
              type="text"
              placeholder="Опис акції"
              value={stockInputs.description}
              onChange={(e) => setStockInputs((prev) => ({ ...prev, description: e.target.value }))}
            />
            <button className="instagram-upload__button" onClick={handleStockUpload} disabled={loading}>
              {loading ? 'Завантаження...' : 'Додати акцію'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
