import React from 'react';
import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth';
import { InputText } from '../../components/Forms/InputText';
import { adminRules } from '../../constans/rules';
import './Admin.css';

export default function Admin() {
  const { control, handleSubmit, getValues } = useForm();
  const navigate = useNavigate(); // Используем useNavigate для навигации

  const auth = getAuth();

  const onSubmit = async () => {
    try {
      const { login, password } = getValues();
      await signInWithEmailAndPassword(auth, login, password);
      navigate('/setting'); // Перенаправление при успешной авторизации
    } catch (error) {
      console.error('Ошибка аутентификации:', error);
    }
  };

  return (
    <div className="admin">
      <div className="form-wrapper">
        <InputText control={control} name="login" label="Логін" type="text" rules={adminRules.login} color="grey" />
        <InputText control={control} name="password" label="Пароль" type="password" rules={adminRules.password} color="grey" />
        <Button sx={{ color: '#526F9F' }} size="large" onClick={handleSubmit(onSubmit)}>
          Увійти
        </Button>
      </div>
    </div>
  );
}
