import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { getDocs, collection } from 'firebase/firestore';
import { db, auth } from './firebase';
import MainRoute from './pages/MainRoute';
import { Context } from './Context';
import DeleteButton from './pages/Admin/DeleteAdmin';
import './App.css';
import './fonts.css';

export default function App() {
  const [language, setLanguage] = useState('ua');
  const [data, setData] = useState(null);
  const [basketNumberLocal, setBasketNumberLocal] = useState([]);
  const [busketNumber, setBusketNumber] = useState(0);
  const [safeFilter, setSafeFilter] = useState(false);
  const [safePage, setSafePage] = useState(false);

  const [offButtonAdmin, setOffButtonAdmin] = useState(false);

  // кнопки администратора
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const fetchData = async () => {
    try {
      // Получаем данные из трех коллекций одновременно
      const [dataSnapshot, instagramSnapshot, stockSnapshot] = await Promise.all([
        getDocs(collection(db, 'data')),
        getDocs(collection(db, 'instagram')),
        getDocs(collection(db, 'stock')),
      ]);

      // Функция преобразования документов в массив
      const formatData = (snapshot) =>
        snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      // .filter((item) => item.id !== "CMEK9tNFjTweJAukAjh2"); // Исключаем определенный товар

      // Создаем объект с данными из всех коллекций
      const newData = {
        products: formatData(dataSnapshot),
        instagram: formatData(instagramSnapshot),
        stock: formatData(stockSnapshot),
      };
      setData(newData);
    } catch (error) {
      console.error('Error getting products: ', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // аторизация пользователя
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user); // Если пользователь авторизован, устанавливаем состояние isAuthenticated в true
    });

    return () => unsubscribe(); // Отписываемся от слушателя при размонтировании компонента
  }, []);

  return (
    <Context.Provider
      value={{
        data,
        setData,
        language,
        setLanguage,
        basketNumberLocal,
        setBasketNumberLocal,
        busketNumber,
        setBusketNumber,
        safeFilter,
        setSafeFilter,
        safePage,
        setSafePage,
        setOffButtonAdmin,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<MainRoute />} />
        </Routes>
        {isAuthenticated && offButtonAdmin && <DeleteButton />}
      </BrowserRouter>
    </Context.Provider>
  );
}
