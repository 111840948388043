import React, { useContext, useEffect } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { useLocation } from 'react-router-dom';
import { Context } from '../../Context';
import './Container.css';

export default function Container({ children }) {
    const { setOffButtonAdmin } = useContext(Context);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('setting')) {
      setOffButtonAdmin(false);
    } else {
      setOffButtonAdmin(true);
    }
    // eslint-disable-next-line
  }, [location]);

  return (
    <div className='container'>
      <Header />
      {children}
      <Footer />
    </div>
  );
}
