import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Context } from '../../Context';
import './Instagram.css';

export default function Instagram() {
  const { language, data } = useContext(Context);
  const isIphone = useMediaQuery({ maxWidth: 576 });

  const [firstInstagramItems, setFirstInstagramItems] = useState([]);
  const [remainingInstagramItems, setRemainingInstagramItems] = useState([]);
  
  useEffect(() => {
    const maxItems = isIphone ? 2 : 3;
    const maxItemsTwo = isIphone ? 1 : 3;
    if (data) {
      const firstItems = data?.instagram.slice(0, maxItems) || [];
      const remainingItems = data?.instagram.slice(maxItems, maxItems + maxItemsTwo) || [];
      setFirstInstagramItems(firstItems);
      setRemainingInstagramItems(remainingItems);
    }
  }, [data, isIphone]);

  return (
    <div name="instagram" className="instagram">
      <div className="instagram-title">{language === 'ua' ? 'Слідкуй за нами в Instagram' : 'Follow us on Instagram'}</div>

      <div className="instagram-icon__heard-wrapper">
        <img className="img" src="./img/heart.svg" alt="сердце" />
      </div>

      <div className="instagram-icon__arrow-wrapper">
        <img className="img" src="./img/arrow.svg" alt="стрілка" />
      </div>

      <div className="instagram-one__wrapper">
        {firstInstagramItems.map((item, index) => (
          <a href={item.link} target="blank" key={index} className="instagram-one__wrapper-item">
            <img className="img-cover" src={item.img[0]} alt="дитяча мебель" />
          </a>
        ))}

        <div className="instagram-one__wrapper-item__null"></div>
      </div>

      <div className="instagram-icon__love-wrapper">
        <img className="img" src="./img/love.svg" alt="лове" />
      </div>

      <div className="instagram-two__wrapper">
        <div className="instagram-one__wrapper-item__info">
          <div className="instagram-icon__circle-wrapper">
            <img className="img" src="./img/circle.svg" alt="круг" />
          </div>
          <div className="instagram-one__wrapper-item__info-text">
            {language === 'ua' ? 'Познач @tuttikids, та стань частиною нашої турботливої спільноти!' : 'Tag @tuttikids and become part of our caring community!'}
          </div>
        </div>

        {remainingInstagramItems.map((item, index) => (
          <a href={item.link} target="blank" key={index} className="instagram-one__wrapper-item">
            <img className="img-cover" src={item.img[0]} alt="дитяча мебель" />
          </a>
        ))}
      </div>
    </div>
  );
}
