import React from 'react';
import { useLocation } from 'react-router-dom';
import ProductsMap from '../../components/ProductsMap/ProductsMap';
import Top from '../../components/Top/Top';
import Instagram from '../../components/Instagram/Instagram';
import './CategoryItem.css';

export default function CategoryItem() {
  const location = useLocation();
  const pathname = location.pathname.substring(1);
  const titleMap = {
    'childrens-beds': 'Дитячі ліжка',
    mattresses: 'Матраси',
    dressers: 'Комоди і пеленальні матраци',
    'bed-sheets': 'Постіль і аксесуари',
    'creativity-and-feeding': 'Меблі для творчості і годування',
  };
  const title = titleMap[pathname] || '';
  return (
    <>
      <div className='category-item'>
        <div className='category-cloud'>
          <img className='img' src="./img/clouds.png" alt="" />
        </div>
        <h2 className='category-item__title'>{title}</h2>
        <div className='category-item-wrapper'>
          <ProductsMap category={pathname} />
        </div>
      </div>
      <Top category={pathname} popular={true} />
      <Instagram />
    </>
  );
}
