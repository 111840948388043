import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyBryouXJ0eZPg00eWBTv6JkcKUsl51Bf40',
  authDomain: 'tutti-kids.firebaseapp.com',
  projectId: 'tutti-kids',
  storageBucket: 'tutti-kids.appspot.com',
  messagingSenderId: '814249888774',
  appId: '1:814249888774:web:10f38fb59f01ad316c4787',
  measurementId: 'G-DG4NX6FJYR',
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage();
export const auth = getAuth(app);
