import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-scroll';
import { IconButton } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import Filter from './Filter/Filter';
import Pagination from './Pagination/Pagination';
import { Context } from '../../Context';
import './ProductsMap.css';

export default function ProductsMap({ category, popular }) {
  const [arrayProduct, setArrayProduct] = useState([]);
  const [arrayProductPopularOne, setArrayProductPopularOne] = useState('');
  const [arrayProductPopularTwo, setArrayProductPopularTwo] = useState('');
  const [arrayProductPopularThree, setArrayProductPopularThree] = useState('');
  const [itemsPerPage, seTitemsPerPage] = useState(12);
  const { data, language, setSafePage, safePage } = useContext(Context);

  // колличество товаров на странице и мобильная версия
  const isIpad = useMediaQuery({ maxWidth: 992 });
  const isIphone = useMediaQuery({ maxWidth: 576 });
  // получаем путь
  const location = useLocation();

  useEffect(() => {
    if (isIpad) seTitemsPerPage(14);
  }, [isIpad]);

  useEffect(() => {
    if (data) {
      if (category) {
        let filteredProducts = data.products.filter((item) => item.category === category && !item.hide);

        if (popular) {
          filteredProducts = filteredProducts.filter((item) => item.popular);
        }
        setArrayProduct(filteredProducts);
      } else if (popular) {
        const filteredProducts = data.products.filter((item) => item.popular && !item.hide);
        setArrayProduct(filteredProducts);
      } else {
        setArrayProduct(data);
      }
    }
  }, [data, category, popular]);

  // переход по ссылке
  const navigate = useNavigate();
  const handleLinkClick = (linkName) => {
    navigate(linkName);
  };

  // популярные стрелочки
  const [startIndexOne, setStartIndexOne] = useState(0);
  const [startIndexTwo, setStartIndexTwo] = useState(1);
  const [startIndexThree, setStartIndexThree] = useState(2);

  const [fadeOutOne, setFadeOutOne] = useState(false);
  const [fadeOutTwo, setFadeOutTwo] = useState(false);
  const [fadeOutThree, setFadeOutThree] = useState(false);

  const [leftDisabled, setleftDisabled] = useState(false);
  const [rightDisabled, setRightDisabled] = useState(false);

  useEffect(() => {
    if (arrayProduct[startIndexOne]) {
      setArrayProductPopularOne(arrayProduct[startIndexOne]);
    }
    if (arrayProduct[startIndexTwo]) {
      setArrayProductPopularTwo(arrayProduct[startIndexTwo]);
    }
    if (arrayProduct[startIndexThree]) {
      setArrayProductPopularThree(arrayProduct[startIndexThree]);
    }

    if (startIndexOne === 0) {
      setleftDisabled(true);
    } else {
      setleftDisabled(false);
    }

    if (startIndexThree > arrayProduct.length - 4) {
      setRightDisabled(true);
    } else {
      setRightDisabled(false);
    }
  }, [startIndexOne, startIndexTwo, startIndexThree, arrayProduct]);

  const touchPopularLeft = () => {
    setTimeout(() => {
      setFadeOutOne(true);

      setTimeout(() => {
        if (startIndexOne - 3 <= 0) {
          setStartIndexOne(0);
        } else {
          setStartIndexOne(startIndexOne - 3);
        }
      }, 300);

      setTimeout(() => {
        setFadeOutOne(false);
      }, 1000);
    }, 600);

    // второе
    setTimeout(() => {
      setFadeOutTwo(true);

      setTimeout(() => {
        if (startIndexTwo - 3 <= 0) {
          setStartIndexTwo(1);
        } else {
          setStartIndexTwo(startIndexTwo - 3);
        }
      }, 300);

      setTimeout(() => {
        setFadeOutTwo(false);
      }, 1000);
    }, 300);

    // третье
    setFadeOutThree(true);

    setTimeout(() => {
      if (startIndexThree - 3 <= 0) {
        setStartIndexThree(2);
      } else {
        setStartIndexThree(startIndexThree - 3);
      }
    }, 300);

    setTimeout(() => {
      setFadeOutThree(false);
    }, 1000);
  };

  // право
  const touchPopularRight = () => {
    // первое
    setFadeOutOne(true);

    setTimeout(() => {
      if (arrayProduct.length < startIndexOne + 3) {
        // Действия при достижении конца списка
        setRightDisabled(true);
      } else {
        setStartIndexOne(startIndexOne + 3);
      }
    }, 300);

    setTimeout(() => {
      setFadeOutOne(false);
    }, 1000);

    // второе
    setTimeout(() => {
      setFadeOutTwo(true);

      setTimeout(() => {
        if (arrayProduct.length <= startIndexTwo + 3) {
          // Действия при достижении конца списка
          setArrayProductPopularTwo('');
          setStartIndexTwo(startIndexTwo + 3);
        } else {
          setStartIndexTwo(startIndexTwo + 3);
        }
      }, 300);

      setTimeout(() => {
        setFadeOutTwo(false);
      }, 1000);
    }, 300);

    // третье
    setTimeout(() => {
      setFadeOutThree(true);

      setTimeout(() => {
        if (arrayProduct.length <= startIndexThree + 3) {
          // Действия при достижении конца списка
          setArrayProductPopularThree('');
          setStartIndexThree(startIndexThree + 3);
        } else {
          setStartIndexThree(startIndexThree + 3);
        }
      }, 300);

      setTimeout(() => {
        setFadeOutThree(false);
      }, 1000);
    }, 600);
  };

  // сраница
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (safePage && location.pathname === safePage.pathname) {
      setCurrentPage(safePage.page);
    } else {
      setCurrentPage(1);
    }
  }, [location, safePage]);

  const handleFilter = (filteredProducts) => {
    // Принимаем отфильтрованные товары и сохраняем их в состоянии
    setArrayProduct(filteredProducts);
  };

  // Вычисляем общее количество страниц на основе количества товаров и количества товаров на странице
  const totalPages = Math.ceil(arrayProduct.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  // Функция для отображения товаров на текущей странице
  const renderProducts = () => {
    return arrayProduct
      .filter((item) => !item.hide)
      .sort((a, b) => {
        // Если у товара есть date, сортируем по дате (новые сверху)
        if (a.date && b.date) {
          return new Date(b.date) - new Date(a.date);
        }

        // Если дата есть только у одного товара, он идет первым
        if (a.date) return -1;
        if (b.date) return 1;

        // Если нет даты, сортируем по id (большие id сверху)
        return b.id - a.id;
      })
      .slice(startIndex, endIndex) // Пагинация ПОСЛЕ сортировки
      .map((item, index) => (
        <Link to={'header'} offset={isIphone ? 100 : 280} onClick={() => handleLinkClick(`/item?id=${item.id}`)} className="products-map-box__item" key={index}>
          <div className="products-map-box__item-picture">
            <img className="products-map-box__item-image" src={item.img ? item.img[0] : '/img/no-image.jpeg'} alt="фото товару" />
          </div>
          <div className="products-map-box__item-title">{language === 'ua' ? item.titleUa : item.titleEn}</div>
          <div className="products-map-box__item-title products-map-box__item-title__mobile">
            {item.price}
            {language === 'ua' ? ' грн' : 'uah'}
            <div className="products-map-box__item-title__mobile-img">
              <img className="img" src="./img/basket-mobile.svg" alt="у кошик" />
            </div>
          </div>
        </Link>
      ));
  };

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setSafePage({ page: pageNumber, pathname: location.pathname });
  };

  return (
    <div id="products" className="products-map__pagination">
      <div className={`${popular ? 'products-map-popular' : 'products-map'}`}>
        {!popular && <Filter category={category} onFilter={handleFilter} />}

        {popular && (
          <IconButton onClick={touchPopularLeft} disabled={leftDisabled}>
            <ArrowBackIosNewOutlinedIcon
              sx={{
                cursor: 'pointer',
              }}
            />
          </IconButton>
        )}

        <div className={`${popular ? 'products-map-box__popular' : 'products-map-box'}`}>
          {!popular ? (
            renderProducts()
          ) : (
            <>
              {arrayProductPopularOne && (
                <Link
                  to={'header'}
                  offset={isIphone ? 100 : 280}
                  onClick={() => handleLinkClick(`/item?id=${arrayProductPopularOne.id}`)}
                  className={`products-map-box__popular-item ${fadeOutOne ? 'products-map-box__popular-item__opacity' : ''}`}
                >
                  <div className="products-map-box__popular-item__picture">
                    <img className="products-map-box__popular-item__picture-cover" src={arrayProductPopularOne.img} alt="зображення товару" />
                  </div>
                  <div className="products-map-box__popular-item__name">{arrayProductPopularOne.titleUa}</div>
                  <div className="products-map-box__popular-item__name">
                    {arrayProductPopularOne.price} {language === 'ua' ? 'грн' : 'uah'}
                  </div>
                  <div className="products-map-box__popular-item__basket">
                    <div className="products-map-box__popular-item__basket-title">{language === 'ua' ? 'у кошик' : 'in the basket'}</div>
                    <div className="products-map-box__popular-item__basket__picture">
                      <img className="img" src="./img/basket-bg.svg" alt="у кошик" />
                    </div>
                  </div>
                </Link>
              )}

              {arrayProductPopularTwo && (
                <Link
                  to={'header'}
                  offset={isIphone ? 100 : 280}
                  onClick={() => handleLinkClick(`/item?id=${arrayProductPopularTwo.id}`)}
                  className={`products-map-box__popular-item ${fadeOutTwo ? 'products-map-box__popular-item__opacity' : ''}`}
                >
                  <div className="products-map-box__popular-item__picture">
                    <img className="products-map-box__popular-item__picture-cover" src={arrayProductPopularTwo.img} alt="зображення товару" />
                  </div>
                  <div className="products-map-box__popular-item__name">{arrayProductPopularTwo.titleUa}</div>
                  <div className="products-map-box__popular-item__name">
                    {arrayProductPopularTwo.price} {language === 'ua' ? 'грн' : 'uah'}
                  </div>
                  <div className="products-map-box__popular-item__basket">
                    <div className="products-map-box__popular-item__basket-title">{language === 'ua' ? 'у кошик' : 'in the basket'}</div>
                    <div className="products-map-box__popular-item__basket__picture">
                      <img className="img" src="./img/basket-bg.svg" alt="у кошик" />
                    </div>
                  </div>
                </Link>
              )}

              {arrayProductPopularThree && (
                <Link
                  to={'header'}
                  offset={isIphone ? 100 : 280}
                  onClick={() => handleLinkClick(`/item?id=${arrayProductPopularThree.id}`)}
                  className={`products-map-box__popular-item ${fadeOutThree ? 'products-map-box__popular-item__opacity' : ''}`}
                >
                  <div className="products-map-box__popular-item__picture">
                    <img className="products-map-box__popular-item__picture-cover" src={arrayProductPopularThree.img} alt="зображення товару" />
                  </div>
                  <div className="products-map-box__popular-item__name">{arrayProductPopularThree.titleUa}</div>
                  <div className="products-map-box__popular-item__name">
                    {arrayProductPopularThree.price} {language === 'ua' ? 'грн' : 'uah'}
                  </div>
                  <div className="products-map-box__popular-item__basket">
                    <div className="products-map-box__popular-item__basket-title">{language === 'ua' ? 'у кошик' : 'in the basket'}</div>
                    <div className="products-map-box__popular-item__basket__picture">
                      <img className="img" src="./img/basket-bg.svg" alt="у кошик" />
                    </div>
                  </div>
                </Link>
              )}
            </>
          )}
        </div>

        {popular && (
          <IconButton onClick={touchPopularRight} disabled={rightDisabled}>
            <ArrowForwardIosOutlinedIcon sx={{ cursor: 'pointer' }} />
          </IconButton>
        )}
      </div>
      {!popular && currentPage && <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />}
    </div>
  );
}
