export function filter(products, filters) {
  return products.filter((product) => {
    return Object.keys(filters).every((key) => {
      if (filters[key] !== false && filters[key] !== undefined) {
        if (key === 'titleUa' && typeof product[key] === 'string') {
          return product[key].toLowerCase().includes(filters[key].toLowerCase());
        } else if (Array.isArray(filters[key])) {
          return filters[key].some((filterValue) => {
            if (Array.isArray(product[key])) {
              return product[key].includes(filterValue);
            } else {
              return product[key] === filterValue;
            }
          });
        } else {
          return product[key] === filters[key];
        }
      } else {
        return true;
      }
    });
  });
}
