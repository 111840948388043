import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Slider } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import { Context } from '../../../Context';
import './Filter.css';

const Filter = ({ onFilter, category }) => {
  const [arrayProduct, setArrayProduct] = useState([]);
  const [selectedCategoryArray, setSelectedCategoryArray] = useState(false);
  const [selectedSubCategoryArray, setSelectedSubCategoryArray] = useState(false);
  const [selectedColor, setSelectedColor] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(false);
  const [selectedSize, setSelectedSize] = useState(false);
  const [filteActive, setFilteActive] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterNotification, setfilterNotification] = useState('Відкрити фільтр');
  const [filterSize, setFilterSize] = useState(false);
  const { data, safeFilter, setSafeFilter, setSafePage } = useContext(Context);

  // размер экрана
  const isIpad = useMediaQuery({ maxWidth: 992 });
  const isIphone = useMediaQuery({ maxWidth: 576 });

  const location = useLocation();

  useEffect(() => {
    if (isIpad && !isIphone) {
      setFilterSize('2vw !important');
    } else if (isIphone && isIpad) {
      setFilterSize('3.4vw !important');
    } else {
      setFilterSize('1vw !important');
    }
  }, [isIpad, isIphone]);

  // получение пути
  const pageName = location.pathname.substring(1);

  useEffect(() => {
    if (data) {
      const filteredProducts = data.products.filter((item) => item.category === category);
      setArrayProduct(filteredProducts);
    }
  }, [data, category]);

  // ссылка на правильный путь
  useEffect(() => {
    // подкатегории
    const categoryArray = {
      childrensBeds: [
        { value: 6, label: 'Маятник з шухлядою' },
        { value: 1, label: 'Маятник без шухляди' },
        { value: 2, label: 'На колесах' },
        { value: 3, label: 'Овальні' },
        { value: 100, label: 'Всі' },
      ],
      mattresses: [
        { value: 3, label: 'Овальні' },
        { value: 4, label: 'Наматрацники' },
        { value: 100, label: 'Всі' },
      ],
      dressers: [
        { value: 5, label: 'Без пеленальної поверхні' },
        { value: 8, label: 'Пеленальні матраци' },
        { value: 100, label: 'Всі' },
      ],
      creativity: [
        { value: 6, label: 'Столики для творчості ' },
        { value: 7, label: 'Стільчики для годування' },
        { value: 100, label: 'Всі' },
      ],
    };

    // const colorArray = {
    //   childrensBeds: [
    //     { value: 'alyaska', label: 'Аляска' },
    //     { value: 'anthracite', label: 'Антрацит' },
    //     { value: 'blue', label: 'Блакитний' },
    //     { value: 'white', label: 'Білий' },
    //     { value: 'gray', label: 'Сірий' },
    //     { value: 'grayAndWhite', label: 'Сіро-білий' },
    //     { value: 'vanilla', label: 'Слонова кістка (Ваніль)' },
    //     { value: 'wenge', label: 'Венге' },
    //     { value: 'nut', label: 'Горіх' },
    //     { value: 'natural', label: 'Натуральний' },
    //     { value: 'pink', label: 'Рожевий' },
    //   ],
    //   dressers: [
    //     { value: 'alyaska', label: 'Аляска' },
    //     { value: 'gray', label: 'Сірий' },
    //     { value: 'grayAndWhite', label: 'Сіро-білий' },
    //     { value: 'white', label: 'Білий' },
    //     { value: 'vanilla', label: 'Слонова кістка(Ваніль)' },
    //     { value: 'wenge', label: 'Венге' },
    //     { value: 'nut', label: 'Горіх' },
    //     { value: 'sonoma', label: 'Дуб сонома' },
    //   ],
    //   bed: [
    //     { value: 'beige', label: 'Бежевий' },
    //     { value: 'blue', label: 'Блакитний' },
    //     { value: 'brown', label: 'Коричневий' },
    //     { value: 'cream', label: 'Кремовий' },
    //     { value: 'gray', label: 'Сірий' },
    //     { value: 'lightGreen', label: 'Салатовий' },
    //     { value: 'olive', label: 'Оливковий' },
    //     { value: 'orange', label: 'Помаранчевий' },
    //     { value: 'peach', label: 'Персиковий' },
    //     { value: 'pink', label: 'Рожевий' },
    //     { value: 'purple', label: 'Фіолетовий' },
    //     { value: 'red', label: 'Червоний' },
    //     { value: 'turquoise', label: 'Бірюзовий' },
    //     { value: 'white', label: 'Білий' },
    //     { value: 'yellow', label: 'Жовтий' },
    //   ],
    //   creativity: [
    //     { value: 'white', label: 'Білий' },
    //     { value: 'vanilla', label: 'Слонова кістка(Ваніль)' },
    //     { value: 'chocolate', label: 'Шоколад' },
    //     { value: 'black', label: 'Чорний' },
    //     { value: 'yellow', label: 'Жовтий' },
    //     { value: 'blue', label: 'Блакитний' },
    //     { value: 'brown', label: 'Коричневий' },
    //     { value: 'beige', label: 'Бежевий' },
    //     { value: 'cream', label: 'Кремовий' },
    //     { value: 'lightGreen', label: 'Салатовий' },
    //     { value: 'olive', label: 'Оливковий' },
    //     { value: 'turquoise', label: 'Бірюзовий' },
    //     { value: 'red', label: 'Червоний' },
    //     { value: 'orange', label: 'Помаранчевий' },
    //     { value: 'purple', label: 'Фіолетовий' },
    //     { value: 'peach', label: 'Персиковий' },
    //     { value: 'gray', label: 'Сірий' },
    //     { value: 'pink', label: 'Рожевий' },
    //   ],
    // };

const colorArray = {
  childrensBeds: [
    { value: 'alyaska', label: 'Аляска' },
    { value: 'anthracite', label: 'Антрацит' },
    { value: 'blue', label: 'Блакитний' },
    { value: 'white', label: 'Білий' },
    { value: 'wenge', label: 'Венге' },
    { value: 'nut', label: 'Горіх' },
    { value: 'gray', label: 'Сірий' },
    { value: 'grayAndWhite', label: 'Сіро-білий' },
    { value: 'natural', label: 'Натуральний' },
    { value: 'pink', label: 'Рожевий' },
    { value: 'vanilla', label: 'Слонова кістка (Ваніль)' },
  ],
  dressers: [
    { value: 'alyaska', label: 'Аляска' },
    { value: 'white', label: 'Білий' },
    { value: 'wenge', label: 'Венге' },
    { value: 'nut', label: 'Горіх' },
    { value: 'sonoma', label: 'Дуб сонома' },
    { value: 'gray', label: 'Сірий' },
    { value: 'grayAndWhite', label: 'Сіро-білий' },
    { value: 'vanilla', label: 'Слонова кістка (Ваніль)' },
  ],
  bed: [
    { value: 'beige', label: 'Бежевий' },
    { value: 'white', label: 'Білий' },
    { value: 'blue', label: 'Блакитний' },
    { value: 'turquoise', label: 'Бірюзовий' },
    { value: 'brown', label: 'Коричневий' },
    { value: 'cream', label: 'Кремовий' },
    { value: 'yellow', label: 'Жовтий' },
    { value: 'gray', label: 'Сірий' },
    { value: 'lightGreen', label: 'Салатовий' },
    { value: 'olive', label: 'Оливковий' },
    { value: 'orange', label: 'Помаранчевий' },
    { value: 'peach', label: 'Персиковий' },
    { value: 'pink', label: 'Рожевий' },
    { value: 'purple', label: 'Фіолетовий' },
    { value: 'red', label: 'Червоний' },
  ],
  creativity: [
    { value: 'beige', label: 'Бежевий' },
    { value: 'white', label: 'Білий' },
    { value: 'blue', label: 'Блакитний' },
    { value: 'turquoise', label: 'Бірюзовий' },
    { value: 'brown', label: 'Коричневий' },
    { value: 'yellow', label: 'Жовтий' },
    { value: 'cream', label: 'Кремовий' },
    { value: 'lightGreen', label: 'Салатовий' },
    { value: 'olive', label: 'Оливковий' },
    { value: 'orange', label: 'Помаранчевий' },
    { value: 'peach', label: 'Персиковий' },
    { value: 'pink', label: 'Рожевий' },
    { value: 'purple', label: 'Фіолетовий' },
    { value: 'gray', label: 'Сірий' },
    { value: 'red', label: 'Червоний' },
    { value: 'chocolate', label: 'Шоколад' },
    { value: 'black', label: 'Чорний' },
  ],
};

    const brandsArray = {
      childrensBeds: [
        { value: 'dubik', label: 'Дубик М' },
        { value: 'babyCofort', label: 'Baby Comfort' },
        { value: 'deSon', label: 'DeSon' },
        { value: 'childrensDream', label: 'Children`s Dream' },
        { value: 'kuzy', label: 'Кузя' },
        { value: 'dominata', label: 'Домінанта' },
        { value: 'flitex', label: 'Flitex' },
        { value: 'tega', label: 'Tega' },
      ],
      mattresses: [
        { value: 'childrensDream', label: 'Children`s Dream' },
        { value: 'babyCofort', label: 'Baby Comfort' },
        { value: 'deSon', label: 'DeSon' },
        { value: 'flitex', label: 'Flitex' },
        { value: 'goodNight', label: 'Mati' },
      ],
      dressers: [
        { value: 'babyCofort', label: 'Baby Comfort' },
        { value: 'cebaBaby', label: 'Ceba Baby' },
        { value: 'deSon', label: 'DeSon' },
        { value: 'dominata', label: 'Домінанта' },
        { value: 'tega', label: 'Tega' },
      ],
      bed: [
        { value: 'babyCofort', label: 'Baby Comfort' },
        { value: 'goodNight', label: 'Mati' },
      ],

      creativity: [
        { value: 'camino', label: 'Camino' },
        { value: 'carrelo', label: 'Carrelo' },
        { value: 'bamby', label: 'Bamby' },
        { value: 'lalakids', label: 'Lalakids' },
        { value: 'deSon', label: 'DeSon' },
      ],
    };

    const sizeArray = {
      mattresses: [
        { value: 6, label: '6см' },
        { value: 7, label: '7см' },
        { value: 8, label: '8см' },
        { value: 9, label: '9см' },
        { value: 10, label: '10см' },
        { value: 11, label: '11см' },
        { value: 12, label: '12см' },
      ],
    };

    if (pageName === 'childrens-beds') {
      setSelectedCategoryArray(categoryArray.childrensBeds);
      setSelectedColor(colorArray.childrensBeds);
      setSelectedBrand(brandsArray.childrensBeds);
    } else if (pageName === 'mattresses') {
      setSelectedCategoryArray(categoryArray.mattresses);
      setSelectedSize(sizeArray.mattresses);
      setSelectedBrand(brandsArray.mattresses);
    } else if (pageName === 'dressers') {
      setSelectedCategoryArray(categoryArray.dressers);
      setSelectedColor(colorArray.dressers);
      setSelectedBrand(brandsArray.dressers);
    } else if (pageName === 'creativity-and-feeding') {
      setSelectedCategoryArray(categoryArray.creativity);
      setSelectedBrand(brandsArray.creativity);
      setSelectedColor(colorArray.creativity);
    } else if (pageName === 'bed-sheets') {
      setSelectedBrand(brandsArray.bed);
      setSelectedColor(colorArray.bed);
    }
  }, [pageName]);

  // начальные значения
  const [priceRange, setPriceRange] = useState([]); // Изначальный диапазон цены
  const [isCheckedColors, setIsCheckedColors] = useState([]); // начальные цвета
  const [isCheckedBrands, setIsCheckedBrands] = useState([]); // значение брендов
  const [isCheckedSize, setIsCheckedSize] = useState([]); // значение размеров

  // загрузка всего из хранилища
  useEffect(() => {
    if (safeFilter && safeFilter.pathname === location.pathname) {
      setPriceRange(safeFilter.price);
    } else {
      setPriceRange([0, 20000]);
    }
    if (safeFilter && safeFilter.pathname === location.pathname) {
      setSelectedSubCategoryArray(safeFilter.subCategory);
    }
    if (safeFilter && safeFilter.colors.length !== 0 && safeFilter.pathname === location.pathname) {
      const initialCheckedStateColor = safeFilter.colors.map((item) => item);
      setIsCheckedColors(initialCheckedStateColor);
      setFilteActive(true);
    }
    if (safeFilter && safeFilter.brands.length !== 0 && safeFilter.pathname === location.pathname) {
      const initialCheckedStateBrands = safeFilter.brands.map((item) => item);
      setIsCheckedBrands(initialCheckedStateBrands);
      setFilteActive(true);
    }
    if (safeFilter && safeFilter.size.length !== 0 && safeFilter.pathname === location.pathname) {
      const initialCheckedStateSize = safeFilter.size.map((item) => item);
      setIsCheckedSize(initialCheckedStateSize);
      setFilteActive(true);
    }
    // eslint-disable-next-line
  }, []);

  // загрузка цвета или false
  useEffect(() => {
    if (selectedColor && !safeFilter) {
      const initialCheckedStateColor = selectedColor.map(() => false);
      setIsCheckedColors(initialCheckedStateColor);
      setFilteActive(true);
    }
    // eslint-disable-next-line
  }, [selectedColor]);

  // загрузка бренда или false
  useEffect(() => {
    if (selectedBrand && !safeFilter) {
      const initialCheckedStateBrands = selectedBrand.map(() => false);
      setIsCheckedBrands(initialCheckedStateBrands);
      setFilteActive(true);
    }
    // eslint-disable-next-line
  }, [selectedBrand]);

  // загрузка размеров или false
  useEffect(() => {
    if (selectedSize && !safeFilter) {
      const initialCheckedStateSize = selectedSize.map(() => false);
      setIsCheckedSize(initialCheckedStateSize);
      setFilteActive(true);
    }
    // eslint-disable-next-line
  }, [selectedSize]);

  // сохранение подкатегории
  useEffect(() => {
    if (selectedSubCategoryArray) {
      setSafeFilter({
        subCategory: selectedSubCategoryArray,
        colors: isCheckedColors,
        brands: isCheckedBrands,
        size: isCheckedSize,
        price: priceRange,
        pathname: location.pathname,
      });
    }
    // eslint-disable-next-line
  }, [selectedSubCategoryArray]);

  // изминение цвета
  const handleCheckboxChangeColors = (index) => (event) => {
    const newCheckedStateColors = [...isCheckedColors];
    newCheckedStateColors[index] = event.target.checked;
    setIsCheckedColors(newCheckedStateColors);
    setSafePage(false);
    setSafeFilter({
      subCategory: selectedSubCategoryArray,
      colors: newCheckedStateColors,
      brands: isCheckedBrands,
      size: isCheckedSize,
      price: priceRange,
      pathname: location.pathname,
    });
  };

  // изминение брендов
  const handleCheckboxChangeBrands = (index) => (event) => {
    const newCheckedStateBrands = [...isCheckedBrands];
    newCheckedStateBrands[index] = event.target.checked;
    setIsCheckedBrands(newCheckedStateBrands);
    setSafePage(false);
    setSafeFilter({
      subCategory: selectedSubCategoryArray,
      colors: isCheckedColors,
      brands: newCheckedStateBrands,
      size: isCheckedSize,
      price: priceRange,
      pathname: location.pathname,
    });
  };

  // изменение размеров
  const handleCheckboxChangeSize = (index) => (event) => {
    const newCheckedStateSize = [...isCheckedSize];
    newCheckedStateSize[index] = event.target.checked;
    setIsCheckedSize(newCheckedStateSize);
    setSafePage(false);
    setSafeFilter({
      subCategory: selectedSubCategoryArray,
      colors: isCheckedColors,
      brands: isCheckedBrands,
      size: newCheckedStateSize,
      price: priceRange,
      pathname: location.pathname,
    });
  };

  // изминение цены
  const handleChangePrice = (event, newValue) => {
    setPriceRange(newValue);
    setSafePage(false);
    setSafeFilter({
      subCategory: selectedSubCategoryArray,
      colors: isCheckedColors,
      brands: isCheckedBrands,
      size: isCheckedSize,
      price: newValue,
      pathname: location.pathname,
    });
  };

  // мотор фильтра
  const handleFilter = () => {
    setOpenFilter(false);
    setfilterNotification('Відкрити фільтр');

    // Фильтрация по цене
    const filteredItemsByPrice = arrayProduct.filter((item) => {
      const { price } = item;
      return price >= priceRange[0] && price <= priceRange[1];
    });

    // фильтрация по подкатегории
    let filteredItemsBySubCategory = filteredItemsByPrice;
    if (selectedSubCategoryArray && selectedSubCategoryArray !== 100) {
      filteredItemsBySubCategory = filteredItemsByPrice.filter((item) => parseInt(item.subСategories, 10) === selectedSubCategoryArray);
    }

    // Фильтрация по цвету, только если есть выбранные цвета
    let filteredItemsByColors = filteredItemsBySubCategory;
    if (selectedColor && isCheckedColors.some((isChecked) => isChecked)) {
      filteredItemsByColors = filteredItemsBySubCategory.filter((product) => {
        const { color } = product;
        const colorIndex = selectedColor.findIndex((item) => item.value === color);
        return isCheckedColors[colorIndex];
      });
    }

    // фильтрация по бренду
    let filteredBrands = filteredItemsByColors;
    if (selectedBrand && isCheckedBrands.some((isChecked) => isChecked)) {
      filteredBrands = filteredItemsByColors.filter((product) => {
        const { brand } = product;
        const brandIndex = selectedBrand.findIndex((item) => item.value === brand);
        return isCheckedBrands[brandIndex];
      });
    }

    // фильтрация по размеру
    let filteredSize = filteredBrands;
    if (selectedSize && isCheckedSize.some((isChecked) => isChecked)) {
      filteredSize = filteredBrands.filter((product) => {
        const size = parseInt(product.size, 10);
        const sizeIndex = selectedSize.findIndex((item) => item.value === size);
        return isCheckedSize[sizeIndex];
      });
    }
    onFilter(filteredSize);
  };

  // активный фильтр
  useEffect(() => {
    handleFilter();
    // eslint-disable-next-line
  }, [isCheckedBrands, isCheckedColors, isCheckedSize, selectedSubCategoryArray]);

  // сброс фильтров
  const handleReset = () => {
    onFilter(arrayProduct);
    setOpenFilter(false);
    setSafePage(false);
    setSafeFilter(false);
    setfilterNotification('Відкрити фільтр');

    if (selectedBrand) {
      const initialCheckedStateBrands = selectedBrand.map(() => false);
      setIsCheckedBrands(initialCheckedStateBrands);
    }

    if (selectedColor) {
      const initialCheckedStateColor = selectedColor.map(() => false);
      setIsCheckedColors(initialCheckedStateColor);
    }

    if (selectedSize) {
      const initialCheckedStateSize = selectedSize.map(() => false);
      setIsCheckedSize(initialCheckedStateSize);
    }

    setSelectedSubCategoryArray(false);

    setPriceRange([0, 20000]);
  };

  const handleOpenFilter = () => {
    setOpenFilter(!openFilter);
    if (openFilter) {
      setfilterNotification('Відкрити фільтр');
    } else {
      setfilterNotification('Закрити фільтр');
    }
  };

  return (
    <>
      <div className={`filter-mobile__title ${openFilter ? 'filter-mobile__title-active' : ''}`} onClick={() => handleOpenFilter()}>
        {filterNotification}
      </div>

      <div className={`filter ${openFilter ? 'filter-open_mobile' : ''}`}>
        {selectedCategoryArray && <div className="filter-title">Тип</div>}

        {selectedCategoryArray && (
          <div className="filter-category-type">
            <div className="filter-category__title">Оберіть тип товару</div>

            {selectedCategoryArray.map((option, index) => (
              <div
                onClick={() => {
                  setSelectedSubCategoryArray(option.value);
                  setSafePage(false);
                }}
                className={`filter-category__list ${selectedSubCategoryArray === option.value ? 'filter-category__list-active' : ''}`}
                key={index}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}

        {selectedColor && <div className="filter-title">Колір</div>}

        {selectedColor && (
          <div className="filter-category">
            <div className="filter-category__title">Оберіть колір товару</div>
            <FormGroup>
              {filteActive &&
                selectedColor.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    label={option.label}
                    labelPlacement="end"
                    control={
                      <Checkbox
                        id={`checkbox1-${index}`} // Уникальный id
                        name={`checkbox1-${index}`} // Уникальное name
                        checked={isCheckedColors[index]}
                        onChange={handleCheckboxChangeColors(index)}
                        sx={{ color: '#557094 !important' }}
                        size="small"
                      />
                    }
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: filterSize,
                        fontFamily: 'Yftoow',
                      },
                      color: '#557094',
                      userSelect: 'none !important',
                    }}
                  />
                ))}
            </FormGroup>
          </div>
        )}

        {selectedBrand && <div className="filter-title">Бренд</div>}

        {selectedBrand && (
          <div className="filter-category">
            <div className="filter-category__title">Оберіть бренд</div>
            <FormGroup>
              {filteActive &&
                selectedBrand.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    control={
                      <Checkbox
                        id={`checkbox2-${index}`} // Уникальный id
                        name={`checkbox2-${index}`} // Уникальное name
                        checked={isCheckedBrands[index]}
                        onChange={handleCheckboxChangeBrands(index)}
                        sx={{ color: '#557094 !important' }}
                        size="small"
                      />
                    }
                    label={option.label}
                    labelPlacement="end"
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: filterSize,
                        fontFamily: 'Yftoow',
                      },
                      color: '#557094',
                      userSelect: 'none !important',
                    }}
                  />
                ))}
            </FormGroup>
          </div>
        )}

        {selectedSize && <div className="filter-title">Висота</div>}

        {selectedSize && (
          <div className="filter-category">
            <div className="filter-category__title">Оберіть висоту товару</div>
            <FormGroup>
              {filteActive &&
                selectedSize.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    control={
                      <Checkbox
                        id={`checkbox3-${index}`} // Уникальный id
                        name={`checkbox3-${index}`} // Уникальное name
                        checked={isCheckedSize[index]}
                        onChange={handleCheckboxChangeSize(index)}
                        sx={{ color: '#557094 !important' }}
                        size="small"
                      />
                    }
                    label={option.label}
                    labelPlacement="end"
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: filterSize,
                        fontFamily: 'Yftoow',
                      },
                      color: '#557094',
                      userSelect: 'none !important',
                    }}
                  />
                ))}
            </FormGroup>
          </div>
        )}

        <div className="filter-title">Ціна</div>
        <div className="filter-category">
          <div className="filter-category__title">Оберіть ціну</div>
          <Slider
            value={priceRange}
            sx={{ color: '#557094 !important' }}
            size="small"
            onChange={handleChangePrice}
            valueLabelDisplay="auto"
            min={0} // Минимальное значение цены
            max={20000} // Максимальное значение цены
            aria-labelledby="price-range-slider"
          />
        </div>

        <div className="filter-button">
          <Link to="products" smooth={true} duration={800} offset={-50} onClick={handleFilter} className="filter-button__wrapper">
            <div className="filter-button-picture">
              <img className="img" src="/img/basket-blue.svg" alt="блакитна" />
            </div>
            <div className="filter-button__click">Застосувати</div>
          </Link>

          <Link to="products" smooth={true} duration={800} offset={-50} onClick={handleReset} className="filter-button__wrapper">
            <div className="filter-button-picture">
              <img className="img" src="/img/filter-button.svg" alt="блакитна" />
            </div>
            <div className="filter-button__clear">Скасувати</div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Filter;
