import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { scroller, Link } from 'react-scroll';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useMediaQuery } from 'react-responsive';
import { Context } from '../../Context';
import Top from '../../components/Top/Top';
import Instagram from '../../components/Instagram/Instagram';
import { loadImages } from '../../constans/imageLoader';
import './Item.css';

export default function Item() {
  const { language, data, setBasketNumberLocal, basketNumberLocal } = useContext(Context);
  const [item, setItem] = useState(false);
  const [colors, setColors] = useState(false);
  const [newColors, setNewColors] = useState(false);
  const [mainImg, setMainImg] = useState('');
  const [fadeOut, setFadeOut] = useState(false);
  const [star, setStarActive] = useState(false);
  const [paragraphsOne, setParagraphsOne] = useState(false);
  const [paragraphsTwo, setParagraphsTwo] = useState(false);
  const [popularCategory, setPopularCategory] = useState('');

  const navigate = useNavigate();

  const isIphone = useMediaQuery({ maxWidth: 576 });

  const handleLinkClick = (linkName) => {
    navigate(linkName);
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const itemId = searchParams.get('id');

  useEffect(() => {
    if (data && itemId) {
      const filteredProducts = data.products.filter((item) => item.id === itemId);
      setPopularCategory(filteredProducts[0].category);
      loadImages(filteredProducts)
        .then((item) => {
          setItem(item[0]);
          setMainImg(item[0].img[0]);
          return item;
        })
        .catch((error) => {
          // Обработка ошибок, если возникли проблемы при загрузке изображений
          // eslint-disable-next-line
          console.error('Ошибка при загрузке изображений:', error);
        });
    }
  }, [itemId, data]);

  useEffect(() => {
    if (item) {
      const filteredProducts = data.products.filter((items) => items.article === item.article && items.id !== item.id);
      loadImages(filteredProducts)
        .then((images) => {
          setColors(images);
          return images; // Верните значение из then()
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error('Ошибка при загрузке изображений:', error);
          throw error;
        });
    }
  }, [item, data]);

  const touchProduct = (item) => {
    setItem(item);
    navigate(`/item?id=${item.id}`, { replace: true });
    setMainImg(item.img[0]);
    if (isIphone) {
      scroller.scrollTo('header', {
        duration: 500,
        smooth: true,
      });
    }
  };

  const [index, setIndex] = useState(0);

  const handleClickLeft = () => {
    const newIndex = index === 0 ? colors.length - 1 : index - 1;
    setIndex(newIndex);
  };

  const handleClickRight = () => {
    const newIndex = index === colors.length - 1 ? 0 : index + 1;
    setIndex(newIndex);
  };

  useEffect(() => {
    if (colors) {
      setNewColors([...colors.slice(index, colors.length), ...colors.slice(0, index)]);
    }
  }, [colors, index]);

  const handleImageClick = (imageGallery) => {
    setFadeOut(true);
    setTimeout(() => {
      setMainImg(imageGallery);
      setFadeOut(false);
    }, 300);
  };

  // добавить в корзину
  const handleItemClick = async (item) => {
    // запуск звезды
    setStarActive(true);
    setTimeout(() => {
      setStarActive(false);
    }, 900);

    const cartItems = await localStorage.getItem('tuttiItems');
    if (cartItems) {
      const cartItemsPars = JSON.parse(cartItems);
      localStorage.setItem('tuttiItems', JSON.stringify([...cartItemsPars, item.id]));
    } else {
      localStorage.setItem('tuttiItems', JSON.stringify([item.id]));
    }
    setBasketNumberLocal([...basketNumberLocal, item.id]);
  };

  // делает обзатцы
  useEffect(() => {
    if (item) {
      const itemCharacteristic = language === 'ua' ? item.characteristicUa : item.characteristicEn;
      setParagraphsOne(itemCharacteristic.split('\n\n'));
      const itemDescription = language === 'ua' ? item.descriptionUa : item.descriptionEn;
      setParagraphsTwo(itemDescription.split('\n\n'));
    }
  }, [item, language]);

  return (
    <>
      <div className="item">
        <div className="item-cloud">
          <img className="img" src="./img/clouds.png" alt="хмара" />
        </div>

        <div className="item-image-one">
          <img className="img" src="./img/star.svg" alt="зірка" />
        </div>

        <div className="item-image-two">
          <img className="img" src="./img/star.svg" alt="зірка" />
        </div>

        <div className="item-image-three">
          <img className="img" src="./img/star.svg" alt="зірка" />
        </div>

        <div className="item-image-four">
          <img className="img" src="./img/star.svg" alt="зірка" />
        </div>

        <div className="item-wrapper">
          <div className="item-info">
            <div className="item-info__photos">
              <div className={`item-info__photos-image ${fadeOut ? 'fade-out' : ''}`}>
                {mainImg ? <img className="img item-info__photos-image__md" src={mainImg.currentSrc} alt="забраження товару" /> : <CircularProgress />}
              </div>

              <div className="item-info__photos-gallery">
                {item.img &&
                  item.img.length > 1 &&
                  item.img.slice(0, 5).map((item, index) => (
                    <div onClick={() => handleImageClick(item)} key={index} className="item-info__photos-gallery-image">
                      <img className="img-cover" src={item.currentSrc} alt="галерея" />
                    </div>
                  ))}
              </div>

              {item.link && (
                <a href={item.link} target="blank" className="item-info__photos-link">
                  <div className="item-info__photos-link-image">
                    <img className="img" src="./img/line.svg" alt="лінія" />
                  </div>

                  <div className="item-info__photos-link__item">
                    <p>Подивитись відео в instagram</p>
                    <img className="item-info__photos-link__item-icon" src="./img/video.png" alt="video icon" />
                  </div>

                  <div className="item-info__photos-link-image">
                    <img className="img" src="./img/line.svg" alt="лінія" />
                  </div>
                </a>
              )}
            </div>

            <div className="item-info__text">
              <div className="item-info__text-title">{language === 'ua' ? item.titleUa : item.titleEn}</div>

              {item && (
                <div className="item-info__text-cost">
                  <div className="item-image-five">
                    <img className="img" src="./img/oval.svg" alt="овал" />
                  </div>
                  <div className="item-info__text-cost__money">
                    {item.price} {language === 'ua' ? 'грн' : 'uah'}
                  </div>
                  <div onClick={!star ? () => handleItemClick(item) : undefined} className="item-info__text-cost__basket">
                    {star && (
                      <div className="item-image-six">
                        <img className="img" src="./img/star.svg" alt="зірка" />
                      </div>
                    )}

                    <div className="item-info__text-cost__basket-title">{language === 'ua' ? 'у кошик' : 'in the basket'}</div>
                    <div className="item-info__text-cost__basket__picture">
                      <img className="img" src="./img/basket-blue.svg" alt="кошик" />
                    </div>
                  </div>
                </div>
              )}

              {colors.length > 0 && <div className="item-color">Інші кольори:</div>}

              {colors.length > 0 ? (
                <div className="item-colors-wrapper">
                  {colors.length > 4 && (
                    <ArrowBackIosIcon
                      sx={{
                        cursor: 'pointer',
                        color: '#afc1df',
                        fontSize: '30px',
                        transition: 'transform 0.5s', // Добавляем плавный переход при изменении стилей
                        '&:hover': {
                          transform: 'scale(1.2)', // Увеличиваем размер на 20% при наведении
                        },
                      }}
                      onClick={handleClickLeft}
                    />
                  )}

                  <div className="item-colors-wrapper__items">
                    {newColors &&
                      newColors.slice(0, 4).map((item, index) => (
                        <div onClick={() => touchProduct(item)} className="item-colors-wrapper-item__picture" key={index}>
                          <img className="img-cover" src={item.img[0].currentSrc} alt="кольори товару" />
                          <div
                            className={`item-colors-wrapper-item__picture-color
                                                ${item.color === 'white' ? 'item-white' : ''}
                                                ${item.color === 'gray' ? 'item-gray' : ''}
                                                ${item.color === 'vanilla' ? 'item-vanilla' : ''}
                                                ${item.color === 'wenge' ? 'item-wenge' : ''}
                                                ${item.color === 'nut' ? 'item-nut' : ''}
                                                ${item.color === 'natural' ? 'item-natural' : ''}
                                                ${item.color === 'pink' ? 'item-pink' : ''}
                                                ${item.color === 'blue' ? 'item-blue' : ''}
                                                ${item.color === 'anthracite' ? 'item-anthracite' : ''}
                                                ${item.color === 'grayAndWhite' ? 'item-grayAndWhite' : ''}
                                                ${item.color === 'sonoma' ? 'item-sonoma' : ''}
                                                ${item.color === 'alyaska' ? 'item-alyaska' : ''}
                                                 `}
                          ></div>
                        </div>
                      ))}
                  </div>

                  {colors.length > 4 && (
                    <ArrowForwardIosIcon
                      sx={{
                        cursor: 'pointer',
                        color: '#afc1df',
                        fontSize: '30px',
                        transition: 'transform 0.5s',
                        '&:hover': {
                          transform: 'scale(1.2)',
                        },
                      }}
                      onClick={handleClickRight}
                    />
                  )}
                </div>
              ) : (
                <div className="item-colors-wrapper"></div>
              )}

              {paragraphsOne && (
                <div className="item-info__text-characteristic">
                  {paragraphsOne.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                  ))}
                </div>
              )}
            </div>
          </div>
          <Link to="header" onClick={() => handleLinkClick('/delivery-and-payment')} className="item-info__text-delivery">
            *Доставка по Україні безкоштовна
          </Link>
          <div className="item-info__photos-description">Опис товару:</div>
          {/* <div className='item-characteristic'>{language === 'ua' ? item.descriptionUa : item.descriptionEn}</div> */}
          {paragraphsTwo && (
            <div className="item-info__text-description">
              {paragraphsTwo.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </div>
          )}
        </div>
      </div>

      {popularCategory && <Top category={popularCategory} popular={true} />}
      <Instagram />
    </>
  );
}
