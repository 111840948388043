export const category = [
  { value: 'childrens-beds', label: 'Дитячі ліжка' },
  { value: 'mattresses', label: 'Матраси та наматрасники' },
  { value: 'dressers', label: 'Комоди' },
  { value: 'bed-sheets', label: 'Постіль' },
  { value: 'creativity-and-feeding', label: 'Меблі для творчості і годування' },
];

export const subСategories = [
  { value: 1, label: 'Маятник без шухляди' },
  { value: 2, label: 'На колесах' },
  { value: 3, label: 'Овальні' },
  { value: 6, label: 'Маятник з шухлядою' },
  { value: 4, label: 'Наматрацники' },
  { value: 5, label: 'Без пеленальної поверхні' },
  { value: 6, label: 'Столики для творчості ' },
  { value: 7, label: 'Стільчики для годування' },
  { value: 8, label: 'Пеленальні матраци' },
];

export const colors = [
  { value: 'white', label: 'Білий' },
  { value: 'gray', label: 'Сірий' },
  { value: 'vanilla', label: 'Слонова кістка(Ваніль)' },
  { value: 'wenge', label: 'Венге' },
  { value: 'nut', label: 'Горіх' },
  { value: 'natural', label: 'Натуральний' },
  { value: 'pink', label: 'Рожевий' },
  { value: 'blue', label: 'Блакитний' },
  { value: 'anthracite', label: 'Антрацит' },
  { value: 'grayAndWhite', label: 'Сіро-білий' },
  { value: 'sonoma', label: 'Дуб сонома' },
  { value: 'alyaska', label: 'Аляска' },
  { value: 'brown', label: 'Коричневий' },
  { value: 'beige', label: 'Бежевий' },
  { value: 'cream', label: 'Кремовий' },
  { value: 'lightGreen', label: 'Салатовий' },
  { value: 'turquoise', label: 'Бірюзовий' },
  { value: 'olive', label: 'Оливковий' },
  { value: 'red', label: 'Червоний' },
  { value: 'orange', label: 'Помаранчевий' },
  { value: 'purple', label: 'Фіолетовий' },
  { value: 'peach', label: 'Персиковий' },
  { value: 'yellow', label: 'Жовтий' },
  { value: 'chocolate', label: 'Шоколад' },
  { value: 'black', label: 'Чорний' },
];

export const brand = [
  { value: 'dubik', label: 'Дубик М' },
  { value: 'babyCofort', label: 'Baby Cofort' },
  { value: 'deSon', label: 'DeSon' },
  { value: 'childrensDream', label: 'Children`s Dream' },
  { value: 'kuzy', label: 'Кузя' },
  { value: 'dominata', label: 'Домінанта' },
  { value: 'flitex', label: 'Flitex' },
  { value: 'goodNight', label: 'Mati' },
  { value: 'camino', label: 'Camino' },
  { value: 'carrelo', label: 'Carrelo' },
  { value: 'bamby', label: 'Bamby' },
  { value: 'lalakids', label: 'Lalakids' },
  { value: 'cebaBaby', label: 'Ceba Baby' },
];

export const size = [
  { value: 6, label: '6см' },
  { value: 7, label: '7см' },
  { value: 8, label: '8см' },
  { value: 9, label: '9см' },
  { value: 10, label: '10см' },
  { value: 11, label: '11см' },
  { value: 12, label: '12см' },
];

export const inputFields = [
  { name: 'titleUa', type: 'text', placeholder: 'Назва товару', req: true },
  { name: 'article', type: 'number', placeholder: 'Артикль товару', req: true },
  { name: 'price', type: 'number', placeholder: 'Ціна', req: true },
  { name: 'link', type: 'text', placeholder: 'Посилання на відео в інстаграм' },
];

export const selectFields = [
  {
    name: 'category',
    placeholder: 'Обрати категорію *',
    options: category,
    req: true,
  },
  {
    name: 'subСategories',
    placeholder: 'Обрати підкатегорію *',
    options: subСategories,
  },
  {
    name: 'brand',
    placeholder: 'Обрати бренд *',
    options: brand,
    // req: true,
  },
  { name: 'color', placeholder: 'Обрати колір *', options: colors, req: true },
  { name: 'size', placeholder: 'Обрати розмір *', options: size },
];

export const textAreaFields = [
  { name: 'descriptionUa', placeholder: 'Опис товару', req: true },
  { name: 'characteristicUa', placeholder: 'Характеристика товару', req: true },
];

export const checkboxes = [
  { name: 'popular', label: 'Додати у популярні' },
  { name: 'hide', label: 'Сховати товар' },
];
